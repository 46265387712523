import { Dispatch, SetStateAction } from 'react';

export interface IProgressLoaderStatus {
  index: number;
  isLoading: boolean;
  isCompleted: boolean;
}

export interface ILoaderDescription {
  headingText: string;
  description: string;
  index: number;
}

export interface IProgressLoaderContext {
  loadingStatus: IProgressLoaderStatus;
  setLoadingStatus: Dispatch<SetStateAction<IProgressLoaderStatus>>;
  openLoader: boolean;
  setOpenLoader: Dispatch<SetStateAction<boolean>>;
  loaderDescription: ILoaderDescription[];
  setLoaderDescription: Dispatch<SetStateAction<ILoaderDescription[]>>;
}

export const initialLoadingStatus = {
  index: 0,
  isLoading: false,
  isCompleted: false,
};

export const initialLoaderDescription = [
  {
    headingText: '',
    description: '',
    index: 0,
  },
];
