import { createContext, useContext, useState } from "react";

import { MediaRunningState } from "types/types";
import { CONTEXTPROVIDERERROR } from "utils/informatives";

const MediaRunningContext = createContext<any>("");

const MediaRunningProvider = ({ children }: any) => {
  const [mediaRunningState, setMediaRunningState] = useState<MediaRunningState>({
    nftId: "",
    state: "paused",
  });

  return (
    <MediaRunningContext.Provider
      value={{
        mediaRunningState,
        setMediaRunningState,
      }}
    >
      {children}
    </MediaRunningContext.Provider>
  );
};
function useMediaRunning() {
  const context = useContext(MediaRunningContext);
  if (context === undefined) {
    throw new Error(CONTEXTPROVIDERERROR("MediaRunningContext"));
  }
  return context;
}

export { MediaRunningProvider, useMediaRunning };
