import { chainRPCs } from 'features/auth/components/chainRPCs';

export const networkConfigs: any = {
  '0x1': {
    currencySymbol: 'ETH',
    blockExplorerUrl: 'https://etherscan.io/',
    wrapped: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  },
  '0x5': {
    currencySymbol: 'ETH',
    blockExplorerUrl: 'https://goerli.etherscan.io/',
  },
  '0xa86a': {
    chainId: 43114,
    chainName: 'Avalanche Mainnet',
    currencyName: 'AVAX',
    currencySymbol: 'AVAX',
    rpcUrl: chainRPCs?.[43114],
    blockExplorerUrl: 'https://cchain.explorer.avax.network/',
  },
  '0xa869': {
    chainId: 43113,
    chainName: 'Avalanche Testnet',
    currencyName: 'AVAX',
    currencySymbol: 'AVAX',
    rpcUrl: chainRPCs?.[43113],
    blockExplorerUrl: 'https://cchain.explorer.avax.network/',
  },
  '0x38': {
    chainId: 56,
    chainName: 'Smart Chain',
    currencyName: 'BNB',
    currencySymbol: 'BNB',
    rpcUrl: chainRPCs?.[56],
    blockExplorerUrl: 'https://bscscan.com/',
    wrapped: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  },
  '0x61': {
    chainId: 97,
    chainName: 'Smart Chain - Testnet',
    currencyName: 'BNB',
    currencySymbol: 'BNB',
    rpcUrl: chainRPCs?.[97],
    blockExplorerUrl: 'https://testnet.bscscan.com/',
  },
  '0x89': {
    chainId: 137,
    chainName: 'Polygon Mainnet',
    currencyName: 'MATIC',
    currencySymbol: 'MATIC',
    rpcUrl: chainRPCs?.[137],
    blockExplorerUrl: 'https://explorer-mainnet.maticvigil.com/',
    wrapped: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
  },
  '0x13881': {
    chainId: 80001,
    chainName: 'Mumbai',
    currencyName: 'MATIC',
    currencySymbol: 'MATIC',
    rpcUrl: chainRPCs?.[80001],
    blockExplorerUrl: 'https://mumbai.polygonscan.com/',
  },
};

export const getNativeByChain = (chain: any) =>
  networkConfigs[chain]?.currencySymbol || 'NATIVE';

export const getChainById = (chain: any) =>
  networkConfigs[chain]?.chainId || null;

export const getExplorer = (chain: any) =>
  networkConfigs[chain]?.blockExplorerUrl;

export const getWrappedNative = (chain: any) =>
  networkConfigs[chain]?.wrapped || null;
