import { createContext, useContext, useState } from 'react';

import { ICollectionERCType } from 'types/types';
import { CONTEXTPROVIDERERROR } from 'utils/informatives';

import { IGetCollectionResponse } from '../api/interface/getCollection';
import {
  ICollectionDataContext,
  ICollectionMetaData,
} from '../types/types.collection';

const CollectionDataContext = createContext<ICollectionDataContext | null>(
  null
);

const CollectionDataProvider = ({ children }: any) => {
  const [collectionMetaData, setCollectionMetaData] =
    useState<ICollectionMetaData>({
      displayName: '',
      symbol: '',
      description: '',
      shortUrl: '',
      profileData: {
        file: '',
        url: '',
      },
    });
  const [selectedERC, setSelectedERC] = useState<ICollectionERCType>('ERC721');
  const [selectedCollection, setSelectedCollection] =
    useState<IGetCollectionResponse>();
  const [newCollectionCreated, setNewCollectionCreated] =
    useState<boolean>(false);
  return (
    <CollectionDataContext.Provider
      value={{
        collectionMetaData,
        setCollectionMetaData,
        selectedERC,
        setSelectedERC,
        selectedCollection,
        setSelectedCollection,
        setNewCollectionCreated,
        newCollectionCreated,
      }}
    >
      {children}
    </CollectionDataContext.Provider>
  );
};

function useCollectionData() {
  const context = useContext(CollectionDataContext);
  if (context === undefined) {
    throw new Error(CONTEXTPROVIDERERROR('CollectionDetail'));
  }
  return context;
}

export { CollectionDataProvider, useCollectionData };
