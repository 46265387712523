export interface LocationStates {
  homePage: '/';
  nftDetailPage: '/detail/:chainId/:contractAddress/:tokenId/:owner/:ercType';
  searchPageNFT: '/search/NFTs/:searchKey';
  searchPageCollection: '/search/collection/:searchKey';
  search: '/search';
  myAssetsPage: '/assets';
  page404: '/page404';
  mintFreePage: '/mint/free';
  mintOnChainPage: '/mint/onChain';
  nftMintingTypePage: '/mint/types';
  ssiWalletAuth: '/trinsic/auth';
  ssiWalletHome: '/trinsic/home';
  nftsPerCollectionPage: '/collection/:chainId/:contractAddress';
  creatorsNFTPage: '/creator/:ethAddress';
  themeSettings:'/theme-settings'
}

export const RoutesPrefixes = {
  homePage: '/',
  nftDetailPage: '/detail',
  nftsPerCollectionPage: '/collection',
  searchPage: '/search',
  myAssetsPage: '/assets',
  creatorsNFTPage: '/creator',
  mintFreePage: '/mint/free',
  mintOnChainPage: '/mint/onChain',
  nftMintingTypePage: '/mint/types',
  ssiWalletAuth: '/ssi/auth',
  ssiWalletHome: '/ssi/home',
  page404: '/page404',
  themeSettings:'/theme-settings'
};

export interface Page {
  path: string;
  exact?: boolean;
  component: JSX.Element;
  isPrivate?: boolean;
}
