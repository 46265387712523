import { createContext, useContext, useState } from 'react';

import { CONTEXTPROVIDERERROR } from 'utils/informatives';

import {
  IAnalyzedPictureContext,
  IAnalyzeSuggestedDescription,
} from '../types/types.mintNFT';

const AnalyzedPictureContext = createContext<IAnalyzedPictureContext | null>(
  null
);

const AnalyzedPictureProvider = ({ children }: any) => {
  const [analyzedImageData, setAnalyzedImageData] =
    useState<Record<string, unknown>>();
  const [suggestedDescription, setSuggestedDescription] =
    useState<IAnalyzeSuggestedDescription>({
      text: '',
      confidence: 0,
      clicked: false,
    });

  return (
    <AnalyzedPictureContext.Provider
      value={{
        analyzedImageData,
        setAnalyzedImageData,
        suggestedDescription,
        setSuggestedDescription,
      }}
    >
      {children}
    </AnalyzedPictureContext.Provider>
  );
};
function useAnalyzedPicture() {
  const context = useContext(AnalyzedPictureContext);
  if (context === undefined) {
    throw new Error(CONTEXTPROVIDERERROR('AnalyzedPictureContext'));
  }
  return context;
}

export { AnalyzedPictureProvider, useAnalyzedPicture };
