import { IMediaFilterFormats, ISortFilter } from 'types/types';

//export const GET_FEATURED_NFTS = 'getFeaturedNFTs';
export const GET_FEATURED_NFTS = 'getFeaturedNFTsV1';
export interface IGetFeaturedNFTsRequestParams {
  chainId: string;
  mediaFormat: IMediaFilterFormats[];
  minPrice: number;
  maxPrice: number;
  sort: ISortFilter;
  shopAddress: {
    chaidId: string;
    shopAddress: string;
  }[];
  chainType?: any;
}
