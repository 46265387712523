export interface IAdminThemeSettingContext {
  selectedThemePerComponent: ISelectedThemePerComponent;
  handleSelectedTheme: ({
    selectedValue,
  }: {
    selectedValue: { name: keyof ISelectedThemePerComponent; value: number };
  }) => void;
  setSelectedThemePerComponent: any;
}

export interface ISelectedThemePerComponent {
  homePageCollectionTheme: number;
  homePageLandingSectionTheme: number;
  homePageFeaturedNFTsTheme: number;
  homePageTopCreatorTheme: number;
  homePageExploreVideoTheme: number;
  homePageExpolreAudioTheme: number;
}

export const initialSelectedThemeData: ISelectedThemePerComponent = {
  homePageCollectionTheme: 0,
  homePageLandingSectionTheme: 0,
  homePageFeaturedNFTsTheme: 0,
  homePageTopCreatorTheme: 0,
  homePageExploreVideoTheme: 0,
  homePageExpolreAudioTheme: 0,
};
