import React, { createContext, useEffect, useState } from 'react';
import { useMoralis } from 'react-moralis';

import { IAuthContext, Web3ProviderType } from 'features/auth/types/types.auth';
import { IMarketPlaceDetailResponse } from 'features/homepage/api/interface/getMarketplaceDetail';
import useHomePageAPIs from 'features/homepage/api/useHomePageAPIs';
import { CONTEXTPROVIDERERROR } from 'utils/informatives';
import storage from 'utils/storage';

import WalletConnectWeb3Connector from '../components/WalletConnectWeb3Connector';

const AuthContext = createContext<IAuthContext | null>(null);

const AuthenticationProvider = ({ children }: any) => {
  const {
    isAuthenticated,
    chainId,
    account,
    isWeb3Enabled,
    isWeb3EnableLoading,
    enableWeb3,
  }: any = useMoralis();
  const { getMarketPlaceDetail } = useHomePageAPIs();

  const [web3provider, setWeb3Provider] = useState<Web3ProviderType>();
  const [chainID, setChainID] = useState<IAuthContext['chainID']>('');
  const [walletAddress, setWalletAddress] =
    useState<IAuthContext['walletAddress']>('');
  const [authenticated, setAuthenticated] =
    useState<IAuthContext['authenticated']>(false);
  const [marketPlaceDetail, setMarketPlaceDetail] =
    useState<IMarketPlaceDetailResponse>();
  const providerName: Web3ProviderType = storage.getWeb3Provider();
  useEffect(() => {
    storage.setChainId(chainId);
    setChainID(chainId);
    setAuthenticated(isAuthenticated);
    setWalletAddress(account);
  }, [isAuthenticated, chainId, account]);

  useEffect(() => {
    setWeb3Provider(providerName);
  }, [providerName]);

  useEffect(() => {
    (async () => {
      if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading) {
        if (providerName === 'walletconnect') {
          await enableWeb3({ connector: WalletConnectWeb3Connector });
        } else {
          await enableWeb3({ provider: providerName });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isWeb3Enabled]);

  useEffect(() => {
    getMarketDetail();
  }, []);

  const getMarketDetail = async () => {
    const data = await getMarketPlaceDetail({
      subscriptionId: process.env.REACT_APP_DEPLOYED_SHOP_ID,
    });
    const availableChains = data?.shopAddress?.map(
      (addresses) => addresses.chaidId,
    );
    storage.setMarketPlace(data?.shopAddress);
    setMarketPlaceDetail({ ...data, availableChains });
  };

  return (
    <AuthContext.Provider
      value={{
        web3provider,
        chainID,
        walletAddress,
        authenticated,
        marketPlaceDetail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuthentication() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(CONTEXTPROVIDERERROR('AuthContext'));
  }
  return context;
}

export { AuthenticationProvider, useAuthentication };
