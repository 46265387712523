import toast from 'react-hot-toast';
import { useMoralis } from 'react-moralis';

import WalletConnectWeb3Connector from 'features/auth/components/WalletConnectWeb3Connector';
import { networkConfigs } from 'helpers/networks';
import storage from 'utils/storage';

const useChains = () => {
  const { isWeb3Enabled, enableWeb3 }: any = useMoralis();

  async function switchCustomNetwork(chain: string) {
    const provider = storage.getWeb3Provider();
    const isWalletConnect = storage.checkIsWalletConnect();
    let web3: any;
    if (provider === 'metamask' || isWalletConnect === false) {
      web3 = await enableWeb3({ provider });
      storage.setWeb3Provider('metamask');
    } else {
      web3 = await enableWeb3({ connector: WalletConnectWeb3Connector });
    }
    if (isWeb3Enabled) {
      try {
        await web3?.provider?.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: chain }],
        });
      } catch (error: any) {
        try {
          const config = networkConfigs[chain];
          const { chainName, currencyName, currencySymbol, rpcUrl } = config;
          await web3?.provider?.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: chain,
                chainName,
                nativeCurrency: {
                  name: currencyName,
                  symbol: currencySymbol,
                  decimals: 18,
                },
                rpcUrls: [rpcUrl],
              },
            ],
          });
        } catch (error: any) {
          toast.error(error.message);
        }
      }
    }
  }
  return { switchCustomNetwork };
};

export default useChains;
