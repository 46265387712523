import { createContext, ReactNode, useContext, useState } from 'react';

import {
  ILoaderDescription,
  initialLoaderDescription,
  initialLoadingStatus,
  IProgressLoaderContext,
  IProgressLoaderStatus,
} from '../types/loaderContext.types';

const ProgressLoaderContextForRunContract =
  createContext<IProgressLoaderContext>({
    loaderDescription: initialLoaderDescription,
    loadingStatus: initialLoadingStatus,
    openLoader: false,
    setLoaderDescription: () => {},
    setLoadingStatus: () => {},
    setOpenLoader: () => {},
  });

const ProgressLoaderForRunContractProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [loadingStatus, setLoadingStatus] =
    useState<IProgressLoaderStatus>(initialLoadingStatus);
  const [loaderDescription, setLoaderDescription] = useState<
    ILoaderDescription[]
  >(initialLoaderDescription);
  const [openLoader, setOpenLoader] = useState<boolean>(false);
  return (
    <ProgressLoaderContextForRunContract.Provider
      value={{
        loadingStatus,
        setLoadingStatus,
        openLoader,
        setOpenLoader,
        loaderDescription,
        setLoaderDescription,
      }}
    >
      {children}
    </ProgressLoaderContextForRunContract.Provider>
  );
};

function useProgressLoader() {
  const context = useContext(ProgressLoaderContextForRunContract);
  if (context === undefined) {
    throw new Error('Please provide Authentication provider');
  }
  return context;
}

export { ProgressLoaderForRunContractProvider, useProgressLoader };
