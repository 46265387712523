export const GET_MARKETPLACE_DETAIL = 'getMarketPlaceDatav1';
export const GET_NFT_TOKENID = 'getTokenId';
export const GET_COLLECTION_ADDRESS = 'getCollectionAddress';

export interface IGetMarketplaceDetailRequestParam {
  subscriptionId: string;
}
export interface IGetNFTTokenIdRequestParam {
  transaction_hash: string;
  chainId: string;
}
export interface IGetCollectionAddressRequestParam {
  transaction_hash: string;
  chainId: string;
  ercType: string;
}

export interface IMarketPlaceDetailResponse {
  shopAddress: {
    chaidId: string;
    shopAddress: string;
  }[];
  status: 'Default' | 'active' | 'inActive';
  shop_name: string;
  shop_symbol: string;
  domain: string;
  createdAt: string;
  updatedAt: string;
  subscriptionId: string;
  shop_desc: string;
  shop_logo: string;
  objectId: string;
  __type: 'Object';
  className: 'Marketplace';
  owner_address: string;
  homePageUI: any;
  availableChains: string[];
  marketItem?: any;
}
