export const chainRPCs = {
  1: 'https://rpc.ankr.com/eth',
  5: 'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
  137: 'https://polygon-rpc.com',
  80001: 'https://rpc-mumbai.maticvigil.com',
  56: 'https://rpc.ankr.com/bsc',
  97: 'https://endpoints.omniatech.io/v1/bsc/testnet/public',
  43114: 'https://rpc.ankr.com/avalanche',
  43113: 'https://api.avax-test.network/ext/bc/C/rpc',
};
