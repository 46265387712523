import { CircularLoadingIcon } from './Logos';

export const Loader = () => {
  return (
    <div className='absolute bg-current bg-opacity-60 z-10 h-full w-full flex items-center justify-center'>
      <div className='flex items-center'>
        <span className='text-3xl mr-4 text-white'>Loading</span>
        <CircularLoadingIcon />
      </div>
    </div>
  );
};
