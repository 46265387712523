import React, { useContext, useState } from 'react';

import { CONTEXTPROVIDERERROR } from 'utils/informatives';

import {
  IProgressContext,
  IProgressDescription,
  IUploadStatus,
} from '../types/types.progress';

const ProgressLoaderContext = React.createContext<IProgressContext | null>(
  null
);

const ProgressLoaderProvider = ({ children }: any) => {
  const [ipfsUploadStatus, setIPFSUploadStatus] = useState<IUploadStatus>({
    isUploading: false,
    isUploaded: false,
  });
  const [metadataUploadStatus, setMetadataUploadStatus] =
    useState<IUploadStatus>({
      isUploading: false,
      isUploaded: false,
    });
  const [nftMintStatus, setNftMintStatus] = useState<IUploadStatus>({
    isUploading: false,
    isUploaded: false,
  });
  const [nftAuctionMintStatus, setnftAuctionMintStatus] =
    useState<IUploadStatus>({
      isUploading: false,
      isUploaded: false,
    });
  const [approveAuctionStatus, setApproveAuctionStatus] =
    useState<IUploadStatus>({
      isUploading: false,
      isUploaded: false,
    });
  const [progressLoader, setProgressLoader] = useState<boolean>(false);
  const [progressLoaderDescription, setProgressLoaderDescription] =
    useState<IProgressDescription[]>();
  return (
    <ProgressLoaderContext.Provider
      value={{
        progressLoader,
        setProgressLoader,
        progressLoaderDescription,
        setProgressLoaderDescription,
        ipfsUploadStatus,
        setIPFSUploadStatus,
        metadataUploadStatus,
        setMetadataUploadStatus,
        nftMintStatus,
        setNftMintStatus,
        nftAuctionMintStatus,
        setnftAuctionMintStatus,
        approveAuctionStatus,
        setApproveAuctionStatus,
      }}
    >
      {children}
    </ProgressLoaderContext.Provider>
  );
};

function useProgressLoaderData() {
  const context = useContext(ProgressLoaderContext);
  if (context === undefined) {
    throw new Error(CONTEXTPROVIDERERROR('ProgressLoader'));
  }
  return context;
}

export { ProgressLoaderProvider, useProgressLoaderData };
