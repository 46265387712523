import KbToMbConvertor from "./KbToMbConvertor";

/* Authentication */
export const AUTHENTICATEERROR = "Please connect wallet first.";
export const AUTHENTICATING = "Authenticating...";

/* IPFS Upload */
export const MORALISFILESAVED = "File saved to Moralis server,";
export const MORALISIPFSSAVED = "File saved to IPFS";
export const METADATAUPLOADED = "Metadata Uploaded successfully.";

/* Run Contract */
export const RUNNINGCONTRACT = "Running Contract...";

/* Upload Media */
export const MAXFILESIZE = (max: number) =>
  `Large File. Maximum limit is ${KbToMbConvertor(max)} MB.`;

/* Context Providers */
export const CONTEXTPROVIDERERROR = (provider: string) =>
  `${`Please provide Provider for ${provider} context on top of the tree.`}`;
