//export const GET_TOP_COLLECTION = 'getTopCollection';

export const GET_TOP_COLLECTION = 'getTopCollectionv1';
export interface IGetTopCollectionRequestParams {
  shopAddress: {
    chaidId: string;
    shopAddress: string;
  }[];
  size: number;
  day: number;
}

export interface IGetTopCollectionResponse {
  name: string;
  image: string;
  owner: string;
  chainId: string;
  contractAddress: string;
  nftImages: string[];
  objectId: string;
  transaction_hash?: string;
  ercType?: string;
}
