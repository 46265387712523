import { Web3ProviderType } from 'features/auth/types/types.auth';

interface shopAddress {
  chaidId: string;
  shopAddress: string;
}
[];

const storage = {
  getWeb3Provider: () => {
    return localStorage.getItem('providerType') as Web3ProviderType;
  },
  setWeb3Provider: (web3Provider: Web3ProviderType) => {
    localStorage.setItem('providerType', web3Provider);
  },
  getTheme: () => {
    return localStorage.getItem('theme') as string;
  },
  setTheme: (theme: string) => {
    localStorage.setItem('theme', theme);
  },
  setMarketPlace: (shopAddress: shopAddress[]) => {
    localStorage.setItem('shopAddress', JSON.stringify(shopAddress));
  },
  getMarketPlace: () => {
    const data = localStorage.getItem('shopAddress');

    if (data !== 'undefined' && JSON.parse(data)) {
      return JSON.parse(data);
    } else {
      return undefined;
    }
  },
  setChainId: (chain: string) => {
    localStorage.setItem('chainId', chain);
  },
  clearLocalStorage: () => {
    localStorage.clear();
  },
  getWalletAuth: () => {
    return localStorage.getItem('walletAuth');
  },
  setWalletAuth: (walletAuth) => {
    localStorage.setItem('walletAuth', walletAuth);
  },
  setSelectedThemePerComponent: (detail) => {
    localStorage.setItem('selectedThemePerComponent', JSON.stringify(detail));
  },
  getSelectedThemePerComponent: () => {
    const data = localStorage.getItem('selectedThemePerComponent');
    if (data !== 'undefined' && JSON.parse(data)) {
      return JSON.parse(data);
    } else {
      return undefined;
    }
  },
  checkIsWalletConnect: () => {
    const getWalletConnect = localStorage.getItem('walletconnect');
    if (getWalletConnect !== 'undefined' && JSON.parse(getWalletConnect)) {
      const parsedData = JSON.parse(getWalletConnect);
      if (parsedData?.connected) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
};

export default storage;
