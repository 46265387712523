import { Toaster } from 'react-hot-toast';

import SwitchWrongNetwork from 'features/SwitchWrongNetwork';
import RouterComponent from 'routers/index';

const App = () => {
  return (
    <div className='bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 overflow-hidden'>
      <Toaster />
      <RouterComponent />
      <SwitchWrongNetwork />
    </div>
  );
};

export default App;
