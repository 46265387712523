import { useQuery } from '@tanstack/react-query';
import { useMoralis } from 'react-moralis';

const useQueryFetching = () => {
  const { Moralis } = useMoralis();
  const useFetchData = ({
    variableToStore,
    cloudFunctionName,
    params,
    dependencies = [],
    initialData,
    condtionalFetch = true,
  }) => {
    const { data, status } = useQuery(
      [variableToStore, ...dependencies],

      async () => {
        return condtionalFetch
          ? await Moralis.Cloud.run(cloudFunctionName, params)
          : initialData;
      }
    );
    if (status === 'success') {
      return { data, status };
    } else {
      return { data: initialData, status };
    }
  };
  return { useFetchData };
};
export default useQueryFetching;
