import React, { useContext, useState } from 'react';

import { CONTEXTPROVIDERERROR } from 'utils/informatives';

import { IMetaDataDetail, INFTCreateContext } from '../types/types.mintNFT';

const NFTCreateDetailContext = React.createContext<INFTCreateContext | null>(
  null
);

const NFTCreateDetailsProvider = ({ children }: any) => {
  const [metadataDetail, setMetaDataDetail] = useState<IMetaDataDetail>({
    name: '',
    description: '',
    mediaDetail: {
      type: '',
      url: '',
      file: null,
    },
    alternateText: '',
    properties: [{ trait_type: '', value: '' }],
    descriptionPurchased: '',
    royaltyFeeNumerator: 10,
    numberofcopies: null,
    unlockContent: '',
  });
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [showAdvanceProperties, setShowAdvanceProperties] =
    useState<boolean>(false);
  const [createCollectionOpen, setCreateCollectionOpen] =
    useState<boolean>(false);

  return (
    <NFTCreateDetailContext.Provider
      value={{
        metadataDetail,
        setMetaDataDetail,
        previewOpen,
        setPreviewOpen,
        showAdvanceProperties,
        setShowAdvanceProperties,
        createCollectionOpen,
        setCreateCollectionOpen,
      }}
    >
      {children}
    </NFTCreateDetailContext.Provider>
  );
};
function useNFTCreateDetail() {
  const context = useContext(NFTCreateDetailContext);
  if (context === undefined) {
    throw new Error(CONTEXTPROVIDERERROR('NFTCreateDetail'));
  }
  return context;
}

export { NFTCreateDetailsProvider, useNFTCreateDetail };
