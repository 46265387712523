import { createContext, useContext, useEffect, useState } from 'react';

import { useAuthentication } from 'features/auth/context/AuthContext';
import { CONTEXTPROVIDERERROR } from 'utils/informatives';

import {
  IAdminThemeSettingContext,
  initialSelectedThemeData,
  ISelectedThemePerComponent,
} from '../types/types.themeSetting';

const AdminThemeSettingContext = createContext<IAdminThemeSettingContext>({
  handleSelectedTheme: () => {},
  selectedThemePerComponent: initialSelectedThemeData,
  setSelectedThemePerComponent: () => {},
});

const AdminThemeSettingProvider = ({ children }: any) => {
  const [selectedThemePerComponent, setSelectedThemePerComponent] =
    useState<ISelectedThemePerComponent>(initialSelectedThemeData);
  const { marketPlaceDetail } = useAuthentication();

  useEffect(() => {
    if (
      typeof marketPlaceDetail?.homePageUI === 'object' &&
      marketPlaceDetail?.homePageUI !== null
    ) {
      setSelectedThemePerComponent(marketPlaceDetail?.homePageUI);
    }
  }, [marketPlaceDetail]);

  const handleSelectedTheme = ({
    selectedValue,
  }: {
    selectedValue: { name: keyof ISelectedThemePerComponent; value: number };
  }) => {
    setSelectedThemePerComponent((prevState) => ({
      ...prevState,
      [selectedValue?.name]: selectedValue?.value,
    }));
  };

  return (
    <AdminThemeSettingContext.Provider
      value={{
        selectedThemePerComponent,
        handleSelectedTheme,
        setSelectedThemePerComponent,
      }}
    >
      {children}
    </AdminThemeSettingContext.Provider>
  );
};
function useAdminThemeSetting() {
  const context = useContext(AdminThemeSettingContext);
  if (context === undefined) {
    throw new Error(CONTEXTPROVIDERERROR('AdminThemeSetting'));
  }
  return context;
}

export { AdminThemeSettingProvider, useAdminThemeSetting };
