import { createContext, useContext, useState } from 'react';

import { CONTEXTPROVIDERERROR } from 'utils/informatives';

import {
  IMintingType,
  IPutOnSaleContext,
  ISaleNFTData,
  ISaleNFTTypes,
} from '../types/types.mintNFT';

const PutOnSaleDataContext = createContext<IPutOnSaleContext | null>(null);

const PutOnSaleDataProvider = ({ children }: any) => {
  const [mintingType, setMintingType] = useState<IMintingType>('gas_free');
  const [saleNFTData, setSaleNFTData] = useState<ISaleNFTData>({
    price: null,
    receiveAmount: null,
    minimumBid: null,
    expirationDate: 86400,
    buyNowPrice: null,
    maxAllowedBid: null,
    increaseBidBy: 1,
  });
  const [selectedSaleNFTType, setSelctedSaleNFTType] = useState<ISaleNFTTypes>({
    id: 0,
    name: 'fixedPrice',
  });
  const [enablePutOnSale, setEnablePutOnSale] = useState<boolean>(true);
  const [unloackPurchased, setUnloackPurchased] = useState<boolean>(false);
  return (
    <PutOnSaleDataContext.Provider
      value={{
        mintingType,
        setMintingType,
        saleNFTData,
        setSaleNFTData,
        selectedSaleNFTType,
        setSelctedSaleNFTType,
        enablePutOnSale,
        setEnablePutOnSale,
        unloackPurchased,
        setUnloackPurchased,
      }}
    >
      {children}
    </PutOnSaleDataContext.Provider>
  );
};

function useSaleData() {
  const context = useContext(PutOnSaleDataContext);
  if (context === undefined) {
    throw new Error(CONTEXTPROVIDERERROR('CollectionDetail'));
  }
  return context;
}

export { PutOnSaleDataProvider, useSaleData };
