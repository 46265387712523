import { AccountService, TrinsicService } from '@trinsic/trinsic/browser';
import { createContext, useContext, useEffect, useState } from 'react';

import { CONTEXTPROVIDERERROR } from 'utils/informatives';
import storage from 'utils/storage';

const WalletAuthContext = createContext(null);
const trinsicService = new TrinsicService();
const accountService = new AccountService();

const WalletAuthProvider = ({ children }: any) => {
  const authenticated = JSON.parse(storage.getWalletAuth());
  const authChange = authenticated?.isAuthenticated;

  const [isAuthenticated, setAuthenticated] = useState(false);
  const [userProfile, setUserProfile] = useState<any>();
  const [serviceProvider, setServiceProvider] = useState({
    trinsicService: null,
    accountService: null,
  });

  useEffect(() => {
    setAuthenticated(authenticated?.isAuthenticated === true ? true : false);
    setUserProfile(authenticated?.profile);
  }, [authChange]);

  useEffect(() => {
    setServiceProvider({ trinsicService, accountService });
  }, []);

  const setAuthToken = () => {
    trinsicService?.setAuthToken(userProfile?.authToken);
  };

  return (
    <WalletAuthContext.Provider
      value={{
        isAuthenticated,
        userProfile,
        setUserProfile,
        setAuthenticated,
        serviceProvider,
        setAuthToken,
      }}
    >
      {children}
    </WalletAuthContext.Provider>
  );
};
function useWalletAuthentication() {
  const context = useContext(WalletAuthContext);
  if (context === undefined) {
    throw new Error(CONTEXTPROVIDERERROR('WalletAuthContext'));
  }
  return context;
}
export { WalletAuthProvider, useWalletAuthentication };
